import { FC, forwardRef, memo } from 'react'
import { InputAdornment } from '@material-ui/core'
import * as S from '../styles'
import type { Props } from '../types'

const Input: FC<Props> = forwardRef(
  (
    {
      id,
      type,
      value,
      onChange,
      label,
      placeholder,
      error,
      errorMessage,
      style,
      disabled,
      endIcon,
      InputProps,
      showIconError,
      required,
      infoTooltip = null,
      ...rest
    },
    ref,
  ) => {
    const endAdornment = endIcon ? (
      <S.IconContainer
        onClick={e => {
          e.stopPropagation()
          endIcon.onClick()
        }}
      >
        <S.Icon name={endIcon.icon} $hasAction={!!endIcon.onClick}></S.Icon>
      </S.IconContainer>
    ) : null

    return (
      <>
        <S.Label htmlFor={id} $error={error}>
          {label}
          {required && ' *'}
          {infoTooltip}
        </S.Label>
        <S.Input
          id={id}
          variant="outlined"
          type={type || 'text'}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={error}
          inputRef={ref}
          style={style}
          disabled={disabled}
          InputProps={{
            endAdornment,
            startAdornment:
              showIconError && error ? (
                <InputAdornment position="start">
                  <S.ErrorIcon name="error" size={14} />
                </InputAdornment>
              ) : null,
            ...InputProps,
          }}
          {...rest}
        />

        {error && errorMessage && (
          <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
        )}
      </>
    )
  },
)

export default memo(Input)
