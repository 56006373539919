import { gql } from '@apollo/client'

export const SHARE_MENTION = gql`
  mutation ShareMentionByEmail($input: SendMentionEmailsInput!) {
    shareMentionByEmail(input: $input)
  }
`

export const UPDATE_MENTION = gql`
  mutation UpdateMention(
    $targets: UpdateMessagesTargets!
    $input: UpdateMessagesInput!
  ) {
    updateMessages(targets: $targets, input: $input) {
      isBackground
      messages {
        id
      }
    }
  }
`
