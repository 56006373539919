import { FC, memo } from 'react'
import { Props } from './types'
import * as S from './styles'

const Tooltip: FC<Props> = ({
  title,
  placement = 'bottom',
  children,
  className = '',
}) => {
  return (
    <S.Tooltip title={title} placement={placement} className={className}>
      <S.ChildrenContainer>{children}</S.ChildrenContainer>
    </S.Tooltip>
  )
}

export default memo(Tooltip)
