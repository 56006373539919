import moment from 'moment'
import {
  flow,
  toPairs,
  set,
  sortBy,
  flatMap,
  isNil,
  reduce,
  trimCharsStart,
  isArray,
  map,
  join,
  reject,
  split,
  filter,
  groupBy,
  mapValues,
  fromPairs,
} from 'lodash/fp'
import requestSocket from '../requestSocket'

const fields = [
  'from',
  'to',
  'source',
  'view',
  'category',
  'theme',
  'feed',
  'author',
  'audience',
  'mentions',
  'sentiment',
  'gender',
  'location',
  'hashtag',
  'text',
  'aspect',
  'age',
  'maritalStatus',
  'interest',
  'device',
  'messageId',
  'mediaType',
  'mainMessage',
  'uncategorized',
  'product',
  'parentId',
  'occupation',
  'url',
  'followers',
  'isDark',
  'type',
  'realTimeInterval',
  'rating',
  'store',
  'geoLocation',
  'reviewPlatform',
  'reviewProduct',
]

const operators = ['or', 'or_additional', 'not', 'and', 'match']

const sources = [
  'TWITTER',
  'FACEBOOK',
  'INSTAGRAM',
  'YOUTUBE',
  'MEDIA',
  'REVIEW',
  'TIKTOK',
  'LINKEDIN',
]

const NUMBER = 'NUMBER'
const BOOL = 'BOOL'
const STRING = 'STRING'
const SOURCE = 'SOURCE'
const OPERATOR = 'OPERATOR'
const RANGE = 'RANGE'
const OBJECT = 'OBJECT'

// Get the type of the field
const getType = key => {
  switch (key) {
    case 'from':
    case 'to':
    case 'realTimeInterval':
      return NUMBER

    case 'source':
      return SOURCE

    case 'mainMessage':
    case 'uncategorized':
    case 'isDark':
      return BOOL

    case 'view':
    case 'product':
      return STRING

    case 'followers':
      return RANGE

    case 'geoLocation':
      return OBJECT

    default:
      return OPERATOR
  }
}

const getTypeKey = type => {
  switch (type) {
    case NUMBER:
      return 'n'

    case SOURCE:
      return 's'

    case BOOL:
      return 'b'

    case STRING:
      return 'm'

    case OPERATOR:
      return 'o'

    case RANGE:
      return 'r'

    case OBJECT:
      return 'j'

    default:
      throw new Error(`Unknown type "${type}"`)
  }
}

function encodeString(str) {
  if (typeof str === 'string')
    return encodeURIComponent(str.replace('*', '*2A').replace('!', '*21'))
  return str
}

function encodeObject(obj) {
  const stringify = JSON.stringify(obj)
  const endoded = encodeString(stringify)
  return endoded
}

const encodeOperator = flow(
  toPairs,
  sortBy(([key]) => operators.indexOf(key)),
  flatMap(([key, val]) => {
    if (!isArray(val)) return [[key, val]]
    return map(v => [key, v], val)
  }),
  map(([key, val]) => `${operators.indexOf(key)}${encodeString(val)}`),
  join('~'),
)

const encodeRange = flow(
  toPairs,
  map(([key, val]) => {
    const prefix = key === 'min' ? '0' : '1'
    return `${prefix}${Number(val).toString(16)}`
  }),
  join('~'),
)

const encodeType = (type, value) => {
  switch (type) {
    case NUMBER:
      return value

    case BOOL:
      return value ? 1 : 0

    case STRING:
      return encodeString(value)

    case OPERATOR:
      return encodeOperator(value)

    case SOURCE:
      return sources.indexOf(value)

    case RANGE:
      return encodeRange(value)

    case OBJECT:
      return encodeObject(value)

    default:
      throw new Error('Unkown type')
  }
}

/**
 * Encode the passed filters as the url-string
 *
 * @param {Object} filters
 * @return {string}
 */
export function encode(filters) {
  return flow(
    toPairs,
    sortBy(([key]) => fields.indexOf(key)),
    reject(k => isNil(k[1])),
    map(([key, val]) => {
      const type = getType(key)
      const typeKey = getTypeKey(type)
      const fieldIndex = fields.indexOf(key)

      if (fieldIndex < 0) {
        throw new Error(`Field "${key}" is not supported in the URL filters.`)
      }

      // TODO: This is a temporal fix using isMoment from momentjs ( deprecated nowadays ) in order to detect if the value is a moment instance.
      // The problem here is that date range become a date format in some point instead remaining as timestamp

      const encodedValue = moment.isMoment(val)
        ? encodeType(type, moment(val).valueOf())
        : encodeType(type, val)
      return `!${fieldIndex}${typeKey}${encodedValue}`
    }),
    join(''),
  )(filters)
}

const fromTypeKey = typeKey => {
  switch (typeKey) {
    case 'n':
      return NUMBER

    case 's':
      return SOURCE

    case 'b':
      return BOOL

    case 'm':
      return STRING

    case 'o':
      return OPERATOR

    case 'r':
      return RANGE

    case 'j':
      return OBJECT

    default:
      throw new Error(`Unknown type key: ${typeKey}`)
  }
}

function decodeString(str) {
  if (!Number.isNaN(Number(str)) && Number(str) < Number.MAX_SAFE_INTEGER) {
    return Number(
      decodeURIComponent(str.replace('*2A', '*').replace('*21', '!')),
    )
  }
  if (typeof str === 'string')
    return decodeURIComponent(str.replace('*2A', '*').replace('*21', '!'))
  return str
}

function decodeObject(obj) {
  const decodedString = decodeString(obj)
  return JSON.parse(decodedString)
}

const decodeOperator = flow(
  split('~'),
  filter(Boolean),
  map(val => {
    const [, opIndex, opValue] = val.match(/([0-9]{1})(.+)/)
    return [operators[opIndex], decodeString(opValue)]
  }),
  groupBy(k => k[0]),
  mapValues(map(k => k[1])),
  op => {
    if (!op.match) return op
    return set('match', op.match[0], op)
  },
)

const decodeRange = flow(
  split('~'),
  reject(isNil),
  reduce((acc, val) => {
    const [type, ...value] = val.split('')
    const key = type === '0' ? 'min' : 'max'

    return {
      ...acc,
      [key]: Number.parseInt(value.join(''), 16),
    }
  }, {}),
)

const decodeValue = (type, value) => {
  switch (type) {
    case NUMBER:
      return Number(value)

    case BOOL:
      return Number(value) === 1

    case STRING:
      return decodeString(value)

    case OPERATOR:
      return decodeOperator(value)

    case SOURCE:
      return sources[Number(value)]

    case RANGE:
      return decodeRange(value)

    case OBJECT:
      return decodeObject(value)

    default:
      throw new Error('Unkown type')
  }
}

/**
 * Decode the passed filter string as the filters object
 *
 * @param {string} str
 * @return {Object} filters
 */
const decodeRegEx = /([0-9]*)([a-z])(.+)/
export function decode(str) {
  // 1- trimCharsStart: Remove the first !
  return flow(
    trimCharsStart('!'),
    split('!'),
    reject(val => isNil(val) || !decodeRegEx.test(val)),
    map(val => {
      const [, keyIndex, typeKey, encodedVal] = val.match(decodeRegEx)

      const type = fromTypeKey(typeKey)

      return [fields[keyIndex], decodeValue(type, encodedVal)]
    }),
    fromPairs,
  )(str)
}

/**
 * Get AlertId from url
 * i.e. ?alert_id=58ecc538e4aefcde34e89c0
 * @returns alertId or null if not found
 */
export const getAlertIdFromParams = () => {
  const alertId = new URLSearchParams(window.location.search).get('alert_id')
  return alertId || null
}

/**
 * Request Alerts and return alert by alertId
 * @param {string} product_id used for request
 * @param {string} alertId the alert to find
 * @returns an Alert object
 */
export async function getAlertById(product_id, alertId) {
  const alerts = await requestSocket('getAlerts', {
    product_id,
  })

  const alert = alerts.find(a => a._id === alertId)
  try {
    return alert
  } catch {
    throw new Error(`Alert with ID ${alertId} not found`)
  }
}

const exploreTrendsRegExp = /(?:explore\/trends)\//

/*
  Match url containing this format `explore/trends/{slug}/dashboard/mentions`
  This kind of url is the result of user interactions with sidebar popups
*/
const exploreRegExp = /(?:explore\/trends)\/([\W\w-]+)\/(?:dashboard\/mentions)/

/*
  Match url containing this format `{slug}/dashboard/content/categories`
  This kind of url is the result of user interactions with sidebar popups
*/
const categoriesRegExp = /\/([\W\w-]+)\/(?:dashboard\/content\/categories)/

export const urlIsExploreTrendsGeneral = url => {
  if (!url) return false

  const er = new RegExp(exploreTrendsRegExp, 'g')
  return er.test(url)
}

/** Checks a url containing explore regexp format.
 * @param {string} url
 * @returns {boolean} True when path is explore trends.
 * */
export const urlIsExploreTrends = url => {
  if (!url) return false

  const er = new RegExp(exploreRegExp, 'g')
  return er.test(url)
}

/** Checks a url containing a regexp format.
 * @param {string} url
 * @returns {boolean} True when path is categories.
 * */
export const urlIsCategories = url => {
  if (!url) return false

  const er = new RegExp(categoriesRegExp, 'g')
  return er.test(url)
}
