import { FC, memo } from 'react'
import type { Props } from '../../types'

const Linkedin: FC<Props> = ({ size = 16, ...rest }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_1358_10241)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.9533 0.5H18.9958C19.8127 0.5 20.4771 1.14562 20.4771 1.94062V19.0556C20.4771 19.8525 19.8127 20.4994 18.9971 20.4994H1.9533C1.13955 20.4994 0.477051 19.8525 0.477051 19.0556V1.94062C0.477051 1.14562 1.13955 0.5 1.9533 0.5ZM6.50381 8.08899H3.51517V17.6866H6.50381V8.08899ZM6.73574 5.04064C6.73574 4.08717 5.96076 3.3122 5.00729 3.3122C4.78015 3.31195 4.55519 3.35647 4.34527 3.44322C4.13534 3.52998 3.94458 3.65725 3.78388 3.81778C3.62318 3.9783 3.49569 4.16893 3.40871 4.37876C3.32173 4.58858 3.27696 4.8135 3.27696 5.04064C3.27812 5.4993 3.46089 5.93884 3.78527 6.26311C4.10965 6.58737 4.54926 6.76997 5.00792 6.77097C5.96076 6.77097 6.73574 5.99411 6.73574 5.04064ZM8.3743 8.08899H8.3699L8.3743 8.0871V8.08899ZM8.3743 8.08899V17.6847H11.3548V12.9368C11.3548 11.686 11.5936 10.473 13.1461 10.473C14.6759 10.473 14.696 11.906 14.696 13.0185V17.6872H17.6771V12.4227C17.6771 9.8388 17.1196 7.85077 14.0989 7.85077C12.642 7.85077 11.669 8.64649 11.2705 9.40072H11.2309V8.08899H8.3743Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_1358_10241">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.477051 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default memo(Linkedin)
