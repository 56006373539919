export type ThemeMode = 'LIGHT' | 'DARK'

const rebrandingColors = {
  black: '#000000',
  gray: {
    5: '#C5CBD3', // icon-disable
    10: '#F2F4F7',
    15: '#E0E2E4',
    20: '#9BA7B5',
    25: '#CCD1D8', // cancel buttons BG
    30: '#5C6B7E',
    35: '#a4afba',
    40: '#7a8d9f',
    45: '#a5afba', // grayFaint
    50: '#4a637c',
    55: '#7E8C9C',
    // Bold titles
    60: '#637181',
    65: '#e0e3e7',
    70: '#F7F8FA',
    75: '#eceff2',
    80: '#E0E0E3',
    90: '#5B5D66',
    120: '#121212E5',
    130: '#A4AFBB',
    140: '#e6e8ec',
    150: '#87888B',
    160: '#bbbbbd',
    170: '#d4d4d6',
    180: '#EEEEF0',
    190: '#282a33',
    200: '#40434c',
    210: '#969699',
    220: '#a7a7aa',
    230: '#F7F7F9',
    240: '#243e59',
    250: '#273f59',
    260: '#7b8d9e',
    270: '#e0e3e8',
    280: '#ebf0f4',
  },
  blue: {
    20: '#E5F6FD',
    30: '#3f75cc',
    40: '#3567b9',
    50: '#4985DC',
    60: '#1E559C',
    70: '#014361',
  },
  green: {
    40: '#5FC169',
  },
  turquoise: {
    10: '#D9FFFC',
    20: '#DFF5F7',
    30: '#C8EBEC',
    40: '#AFE2E0',
    50: '#89D5CC',
    60: '#70CCC4',
    70: '#43BBB9',
    80: '#43BBB9',
    90: '#61B3B4',
    100: '#3CA7A7',
  },
  mermaid: {
    20: '#67DEB8',
    30: '#10D4AB',
    40: '#0ebb96',
  },
  red: {
    5: '#FDEDED',
    10: '#D32F2F',
    20: '#CC2B29',
    25: '#E6312EE5',
    30: '#E6312E',
    60: '#5F2120',
    70: '#DE6047',
  },
  white: {
    0: '#fff',
    5: '#eceff2',
    10: '#e0e3e7',
    20: '#f8fafc',
    25: '#eef4fa',
  },
  orange: {
    10: '#FFF4E5',
    30: '#FFAE00',
    50: '#ED6C02',
    80: '#663C00',
  },
  purple: {
    50: '#9A3998',
  },
  violet: {
    20: '#edddf9',
    70: '#B97DE6',
    80: '#B473E5',
  },
}

const colorsToMove = {
  white: '#FFFFFF',
  cloudy: '#F2F4F7',
  deepBlue: '#1F2B59',
  mermaid: '#10D4AB',
  royalBlue: '#0A569F',
  skyBlue: '#4C87D9',
  violet: '#8b6ec8',
  darkGray: '#4A637C',
  deepGray: '#192541',
  gray: '#7A8D9F',
  grayPlain: '#7e8c9c',
  lightGray: '#A4AFBB',
  subLightGray: '#A4AFBA',
  lightGray2: '#637181',
  smoky: '#CCD1D8',
  borderGray: '#f4f5f7',
  error: '#E6312E',
  errorDark: '#C32927',
  barchart: {
    blue: '#77A7E9',
    zeroBars: '#DEE3EC',
    positive: '#79EAD0',
    negative: '#FF5A55',
    objective: '#FFAA00',
  },
  chartsColors: [
    '#e3abff',
    '#ff8fe4',
    '#64ebbb',
    '#8add88',
    '#adbcff',
    '#cbec4f',
    '#7c85c1',
    '#ffde4b',
    '#6baff3',
    '#ffb448',
    '#3aabc5',
    '#dc8d6a',
    '#60f4fe',
    '#ff88a0',
  ],
  sentiment: {
    negative: '#E6312E',
    positive: '#68BF39',
    objective: '#FFAE00',
  },
  // The grayscale is a special set of colors. It's only meant to be used
  // to create the blankstate. The contrast is too low and should never be
  // used in text elements.
  grayscale: {
    light: '#F2F4F7',
    primary: '#CED4DA',
    dark: '#B1B4BD',
  },
  source: {
    twitter: '#000',
    facebook: '#405392',
    instagram: '#000000',
    tiktok: '#000000',
    youtube: '#FF0000',
    media: '#FF8D03',
    linkedin: '#0288D1',
    review: '#4989F5',
    webzioReview: '#9b3998',
    amazon: '#333333',
  },
}

const light = {
  name: 'LIGHT' as ThemeMode,
  colors: { ...colorsToMove },
  colours: rebrandingColors,
}

export type Theme = typeof light
const themes = {
  light,
  dark: {}, // future implementation,
}

export default themes as { [key in keyof typeof themes]: Theme }
