/* eslint-disable react/no-unused-state,jsx-a11y/click-events-have-key-events */
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import mousetrap from 'mousetrap'
import {
  filter,
  flow,
  get,
  intersection,
  isEmpty,
  isEqual,
  join,
  last,
  map,
  split,
  uniqueId,
  trim,
} from 'lodash/fp'
import { withTranslation } from 'react-i18next'

import Tag from 'components/Tag/Tag'
import Icon from 'components/Icon/Icon'
import Tooltip from 'components/Tooltips/MuiTooltip/Tooltip'

import './TagsInput.css'
import * as common from '../styles'
import * as component from './styles'

const S = {
  ...common,
  ...component,
}

/**
 * This component does not keep the state.
 * You are expected to linsted for the onChange and keep the state.
 */

const BLACKLISTED_CHARS = [',', ':']
const KEYBOARD_SHORTCUTS = ['enter', 'esc', 'backspace', 'mod+a', 'mod+v']

export const tagShape = PropTypes.shape({
  value: PropTypes.string.isRequired,

  // Special marks the tag as different. For example a special tag when using
  // the case-sensitive options is a case-sensitive tag
  special: PropTypes.bool,
})

const propTypes = {
  // Array of tags to display
  tags: PropTypes.arrayOf(tagShape),

  // Same as a standard field
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,

  // Shown beside the label, used in config
  helpText: PropTypes.string,

  // When specified, the input will have special mode enabled
  specialIcon: PropTypes.string,
  specialLabel: PropTypes.string,
  withRTs: PropTypes.bool,
  RTsLabel: PropTypes.string,

  // When specified, the input will have the matches mode enabled
  hasMatches: PropTypes.bool,

  // Render a copy to clibaord button
  copyButton: PropTypes.bool,

  required: PropTypes.bool,

  // Fired when changing the tags with as argument the `tags` array
  onChange: PropTypes.func,

  // Same as a standard DOM input
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  // Firex when the user interaction is considered complete, ivoked with `tags`
  onSubmit: PropTypes.func,

  // withTranslation HOC
  t: PropTypes.func.isRequired,

  className: PropTypes.string,

  addCharsToBlock: PropTypes.arrayOf(PropTypes.string),
}

const defaultProps = {
  copyButton: false,
  error: '',
  hasMatches: false,
  helpText: '',
  label: '',
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onSubmit: () => {},
  placeholder: '',
  RTsLabel: '',
  specialIcon: '',
  specialLabel: '',
  tags: [],
  withRTs: false,
  classNames: '',
  addCharsToBlock: [],
}

const parseTags = map(t => ({
  ...t,
  value: trim(t.value),
  id: uniqueId('tag'),
}))

const stripIds = map(t => ({ ...t, id: undefined }))

export const getPlainTextTags = flow(map(get('value')), join(','))

class TagsInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tags: parseTags(props.tags),

      // Value is what the user is typing in the input
      value: '',

      // When in focus
      isFocused: false,

      // When in selection mode renders a texarea containing comma-separated
      // list of tag values
      isSelectMode: false,

      // Paste mode is used for handling when the user pastes content
      isPasteMode: false,

      // When true the created tags will be marked as special
      isSpecial: false,

      // When true the created tags will add RTs of [user]
      withRTs: false,

      // When true the created tags will be marked as matches
      isMatches: false,

      // ID internally assigned to the input. You mostly don't want to change
      // this
      id: uniqueId('TagsInput_'),
    }

    // Input events
    this.handleBlurInput = this.handleBlurInput.bind(this)
    this.handleFocusInput = this.handleFocusInput.bind(this)
    this.handleChangeInput = this.handleChangeInput.bind(this)
    this.handleClickFauxInput = this.handleClickFauxInput.bind(this)
    this.handleShortcut = this.handleShortcut.bind(this)

    // Textarea for copy/paste
    this.handleBlurTextarea = this.handleBlurTextarea.bind(this)
    this.handleFocusTextarea = this.handleFocusTextarea.bind(this)

    // Special buttons
    this.handleClickSpecial = this.handleClickSpecial.bind(this)
    this.handleClickMatches = this.handleClickMatches.bind(this)
    this.handleClickRTsOf = this.handleClickRTsOf.bind(this)

    // Other events
    this.handleClickTagButton = this.handleClickTagButton.bind(this)
    this.handleClickCopyText = this.handleClickCopyText.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.tags, nextProps.tags)) {
      this.setState({
        tags: parseTags(nextProps.tags),
        value: '',
        isPasteMode: false,
      })
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    // When the state is in pasteMode and we have a value, parse the value
    // and reset it
    if (nextState.isPasteMode && nextState.value) {
      const val = this.parseValue(nextState.value)
      if (!val.length) return

      const tags = [...nextProps.tags, ...val]

      // We can't change the state here, rely on the onChange event to reset
      // the state.
      //
      // It works a folows:
      //   1. onChange is fired with new tags
      //   2. The element using this component will pass down the new tags
      //   3. In the  UNSAFE_componentWillReceiveProps lyfecycle the value is reset
      nextProps.onChange(tags)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // When going from select mode to normal, focus again on the input.
    // This must live in the didUpdate lyfecycle because the filed needs to be
    // already renderered with the correct params.
    if (prevState.isSelectMode && !this.state.isSelectMode) {
      this.input.focus()
    }
  }

  hasBlackListerChars(str) {
    const { addCharsToBlock } = this.props

    return !isEmpty(
      intersection([...BLACKLISTED_CHARS, ...addCharsToBlock], split('', str)),
    )
  }

  parseValue(value) {
    return flow(
      split(','),
      map(value => {
        if (typeof value !== 'string') return false
        if (this.hasBlackListerChars(value)) return false
        return { value: trim(value) }
      }),
      filter(Boolean),
    )(value)
  }

  handleFocusInput() {
    // Ignore the event in selectMode
    if (this.state.isSelectMode) return

    this.setState({ isFocused: true })
    this.props.onFocus()
    this.attachListeners()
  }

  handleBlurInput() {
    // Create the tag when leaving the input,
    // without needing to press enter or add a comma
    if (this.state.value.length) {
      this.createTag()
    }

    // Ignore the blur event in selectMode
    if (this.state.isSelectMode) return

    this.setState({ isFocused: false })
    this.props.onBlur()
    this.removeListeners()
  }

  handleChangeInput(event) {
    const { value } = event.target
    const { addCharsToBlock } = this.props

    const lastChar = last(value)

    // If the value ends with a comma and it's not just a comma create a new
    // tag.
    // The order is imorant, this conditions goes before the blacklisting
    if (value.length > 1 && lastChar === ',') {
      return this.createTag()
    }

    // Ignore leading white spaces
    if (value.length === 1 && value === ' ') return null

    const blockedChars = [...BLACKLISTED_CHARS, ...addCharsToBlock]
    if (blockedChars.includes(lastChar)) return null

    return this.setState({ value })
  }

  handleClickFauxInput(event) {
    if (this.state.isFocused) {
      event.preventDefault()
      event.stopPropagation()

      return
    }

    this.input.focus()
  }

  handleFocusTextarea() {
    document.execCommand('selectall', null, false)
    mousetrap.bind('mod+c', () => this.handleShortcut('mod+c'))
  }

  handleBlurTextarea() {
    setTimeout(() => {
      // Don't blur when clicking on the copy button
      if (!['INPUT'].includes(document.activeElement.nodeName)) {
        this.setState({ isSelectMode: false })
        mousetrap.unbind('mod+c')
      }
    }, 200)
  }

  /**
   * Fire a change event without the passed tag
   */
  handleClickTagButton(id) {
    const tags = flow(
      filter(t => t.id !== id),
      stripIds,
    )(this.state.tags)

    this.props.onChange(tags)
  }

  handleClickCopyText() {
    this.input.focus()
    this.setState(state => ({ isSelectMode: !state.isSelectMode }))
  }

  /**
   * Toggle the special state
   */
  handleClickSpecial() {
    this.setState(state => ({
      isSpecial: !state.isSpecial,
      isMatches: false,
      withRTs: false,
    }))
  }

  /**
   * Toggle the retweets of state
   */
  handleClickRTsOf() {
    this.setState(state => ({
      isSpecial: false,
      isMatches: false,
      withRTs: !state.withRTs,
    }))
  }

  /**
   * Toggle the matches state
   */
  handleClickMatches() {
    this.setState(state => ({
      isMatches: !state.isMatches,
      isSpecial: false,
      withRTs: false,
    }))
  }

  handleShortcut(type) {
    switch (type) {
      // Create a new tag
      case 'enter': {
        // Whe the user press enter and there is no text currently being
        // wrtitten, fire the onSubmit event
        if (!this.state.value.length) return this.submitTags()

        return this.createTag()
      }

      case 'esc': {
        // Stop the edit mode if we are on it
        if (this.state.isSelectMode)
          return this.setState({ isSelectMode: false })

        // Reset the input value when pressing esc if we have a value
        if (this.state.value) return this.setState({ value: '' })

        return this.input.blur()
      }

      // When the value of the input is empty and the user presses backspace,
      // delete the previous tag
      case 'backspace': {
        if (this.state.value.length || !this.props.tags.length) return null
        if (this.state.isSelectMode) return null

        // Remove the last tag in the array (in an immutable way)
        const tags = [...this.props.tags]
        tags.pop()

        return this.props.onChange(tags)
      }

      // Select the content of the plaintext field
      case 'mod+a': {
        // Ignore the event if the user is typing
        if (this.state.value.length) return null

        return this.setState({ isSelectMode: true })
      }

      // The user copied to the clipboard, so reset the selcted state
      case 'mod+c':
        return window.setTimeout(
          () => this.setState({ isSelectMode: false }),
          100,
        )

      // When pasting, we'll receive the data in the next state update
      case 'mod+v':
        return this.setState({ isPasteMode: true })

      default:
        return null
    }
  }

  /**
   * Listen for keys and key combinations
   */
  attachListeners() {
    KEYBOARD_SHORTCUTS.forEach(key =>
      mousetrap.bind(key, () => this.handleShortcut(key)),
    )
  }

  /**
   * Remove all the document listeners
   */
  removeListeners() {
    KEYBOARD_SHORTCUTS.forEach(key => mousetrap.unbind(key))
  }

  /**
   * Create a tag from the current state value
   */
  createTag() {
    const { value, withRTs, isMatches, isSpecial } = this.state
    const tags = [
      ...this.state.tags,
      {
        value: trim(value),
        special: isSpecial,
        matches: isMatches,
      },
    ]
    if (withRTs) {
      tags.push({
        value: trim(value),
        special: isSpecial,
        matches: isMatches,
        withRTs,
      })
    }

    this.props.onChange(tags)

    return false
  }

  submitTags() {
    const tags = stripIds(this.state.tags)
    this.props.onSubmit(tags)
  }

  textareaRef(el) {
    if (!el) return

    // Focus on the textarea
    el.focus()
  }

  /**
   * The header block contains:
   *  - Label
   *  - Special button with tooltip
   */
  renderHeaderBlock() {
    const {
      label,
      helpText,
      specialIcon,
      specialLabel,
      withRTs,
      RTsLabel,
      hasMatches,
      error,
      required,
    } = this.props

    // When neither label or special icon is specified, render nothing
    if (!label && !specialIcon && !hasMatches) return null

    let labelBlock
    let buttonBlock
    let matchesBlock
    let withRTsBlock

    if (label) {
      const labelClassName = classNames('c-TagsInput__label', {
        'is-error': error,
      })

      let helpTextSpan
      if (helpText) {
        helpTextSpan = (
          <span className="c-TagsInput__help-text">{helpText}</span>
        )
      }

      labelBlock = (
        <label className={labelClassName} htmlFor={this.state.id}>
          {label}
          {required && ' *'}
          {helpTextSpan}
        </label>
      )
    }

    if (specialIcon) {
      buttonBlock = (
        <Tooltip title={specialLabel} placement="top">
          <S.TooltipChildren>
            <S.SpecialButton
              onClick={this.handleClickSpecial}
              actived={this.state.isSpecial}
            >
              <Icon name={specialIcon} />
            </S.SpecialButton>
          </S.TooltipChildren>
        </Tooltip>
      )
    }

    if (withRTs) {
      withRTsBlock = (
        <Tooltip title={RTsLabel} placement="top">
          <S.TooltipChildren>
            <S.SpecialButton
              onClick={this.handleClickRTsOf}
              size="small"
              actived={this.state.withRTs}
            >
              <Icon name="rt" />
            </S.SpecialButton>
          </S.TooltipChildren>
        </Tooltip>
      )
    }

    if (hasMatches) {
      const title = this.props.t(
        'common:includedTheTerm',
        'Contiene el término',
      )
      matchesBlock = (
        <Tooltip title={title} placement="top">
          <S.TooltipChildren>
            <S.SpecialButton
              onClick={this.handleClickMatches}
              size="small"
              actived={this.state.isMatches}
            >
              <Icon name="include-term" />
            </S.SpecialButton>
          </S.TooltipChildren>
        </Tooltip>
      )
    }

    return (
      <div className="c-TagsInput__header">
        {labelBlock}
        <div className="c-TagsInput__header-buttons">
          {buttonBlock}
          {matchesBlock}
          {withRTsBlock}
        </div>
      </div>
    )
  }

  /**
   * The footer block contains:
   *  - Copy button
   *  - Erorr message
   */
  renderFooterBlock() {
    const { copyButton, error } = this.props
    const copyLabel = this.state.isSelectMode
      ? this.props.t('actions:cancel', 'Cancelar')
      : this.props.t('actions:selectAll', 'Seleccionar todos')

    // Render nothing when neither an error nor the button in required
    if (!copyButton && !error) return null

    let buttonBlock
    let errorBlock
    if (copyButton) {
      buttonBlock = (
        <button
          className="c-TagsInput__copy-btn"
          onClick={this.handleClickCopyText}
        >
          {typeof copyButton === 'string' ? copyButton : copyLabel}
        </button>
      )
    }

    if (error) {
      switch (typeof error) {
        case 'object':
          errorBlock = (
            <div>
              {Object.keys(error).map(key => (
                <div className="c-TagsInput__error c-TagsInput__error-paragraph">
                  {error[key]}: {key}
                </div>
              ))}
            </div>
          )

          break
        default:
          errorBlock = <span className="c-TagsInput__error">{error}</span>
      }
    }

    return (
      <div className="c-TagsInput__footer">
        {errorBlock}
        {buttonBlock}
      </div>
    )
  }

  renderActiveTags() {
    const { specialIcon } = this.props

    /* eslint-disable no-nested-ternary */
    return this.state.tags.map(tag => (
      <div className="c-TagsInput__tag" key={tag.id}>
        <Tag
          icon={
            tag.withRTs
              ? 'rt'
              : tag.special
              ? specialIcon
              : tag.matches
              ? 'include-term'
              : ''
          }
          showButton
          onClickButton={() => this.handleClickTagButton(tag.id)}
          operator={tag.operator}
        >
          <p className="c-TagsInput__tag__text"> {tag.value}</p>
        </Tag>
      </div>
    ))
  }

  render() {
    const { error, placeholder } = this.props

    const headerBlock = this.renderHeaderBlock()
    const footerBlock = this.renderFooterBlock()

    const inputRef = el => {
      this.input = el
    }
    const inputWidth = `${this.state.value.length + 1}ch`
    const inputClassName = classNames('c-TagsInput__input', {
      'is-focused': this.state.isFocused || this.state.isSelectMode,
      'is-selected': this.state.isSelectMode,
      'is-error': error,
    })

    let activeTags

    // In selectMode render a texarea whith the content already pre-selected.
    // Why a texarea? Beacause it can wrap to the next line, an input can't
    if (this.state.isSelectMode) {
      // Calculate the rows to make the textarea grow (rows="5" -> 5em)
      const tags = getPlainTextTags(this.state.tags)
      const rows = tags.length / 40 > 5 ? tags.length / 40 : 5
      activeTags = (
        <textarea
          style={{ height: `${rows}em` }}
          className="c-TagsInput__textarea mousetrap"
          value={tags}
          ref={el => this.textareaRef(el)}
          onFocus={this.handleFocusTextarea}
          onBlur={this.handleBlurTextarea}
          readOnly
        />
      )
    } else {
      activeTags = this.renderActiveTags()
    }

    /**
     * Notes:
     *
     * 1. The class 'mousetrap' is required for the library to fire the events.
     *    See https://craig.is/killing/mice#api.bind.text-fields
     */

    let placeholderBlock
    if (placeholder && !this.state.value && !this.state.tags.length) {
      placeholderBlock = (
        <span className="c-TagsInput__placeholder">{placeholder}</span>
      )
    }

    return (
      <div className={`c-TagsInput ${this.props.className}`}>
        {headerBlock}

        <div className="c-TagsInput__field">
          {/* eslint-disable jsx-a11y/no-static-element-interactions */}
          <S.CustomInputcontainer
            className={inputClassName}
            onClick={this.handleClickFauxInput}
          >
            {/* eslint-enable jsx-a11y/no-static-element-interactions */}
            {activeTags}
            {placeholderBlock}

            <input
              type="text"
              id={this.state.id}
              style={{ width: inputWidth }}
              className="c-TagsInput__text mousetrap"
              value={this.state.value}
              ref={inputRef}
              onChange={this.handleChangeInput}
              onBlur={this.handleBlurInput}
              onFocus={this.handleFocusInput}
            />
          </S.CustomInputcontainer>
        </div>

        {footerBlock}
      </div>
    )
  }
}

TagsInput.propTypes = propTypes
TagsInput.defaultProps = defaultProps

export default withTranslation(['actions', 'common'])(TagsInput)
