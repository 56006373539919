const overrides = {
  MuiTooltip: {
    tooltip: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
}

export default overrides
