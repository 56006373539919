import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { PURGE } from 'redux-persist'
import {
  set,
  get,
  flow,
  isEmpty,
  difference,
  omit,
  pick,
  remove,
  isEqual,
  omitBy,
  isNil,
  isObject,
  isArray,
  mapValues,
  merge,
  concat,
  without,
  defaultTo,
  identity,
  isString,
} from 'lodash/fp'
import {
  encode,
  decode,
  getAlertIdFromParams,
  getAlertById,
  urlIsExploreTrends,
} from '@utils/urlFilters'
// For backwards-compability, this reduce reacts to the events emitted by the
// reducer that it replaces.
import {
  SET_DATE_RANGE as GLOBAL_SET_DATE_RANGE,
  SET_DEFAULT_DATE as GLOBAL_SET_DEFAULT_DATE,
  SET_SOURCE as GLOBAL_SET_SOURCE,
  SET_USER_FILTER as GLOBAL_SET_USER_FILTER,
  SET_VIEW as GLOBAL_SET_VIEW,
  SET_PRODUCT as GLOBAL_SET_PRODUCT,
  SET_FEED as GLOBAL_SET_FEED,
  RESET_FEED as GLOBAL_RESET_FEED,
  SET_ALLOWED_SOURCES as GLOBAL_SET_ALLOWED_SOURCES,
  RESET_ALLOWED_SOURCES as GLOBAL_RESET_ALLOWED_SOURCES,
  FILTERS_REHYDRATE,
  UPDATE_REHYDRATED,
  // This will eventually be replace by GraphQL.
  maybeFetchViews,
  SET_FILTERS_FROM_ALERT,
  CLEAR_ALERT_ID,
  setFiltersFromAlert,
  setView,
} from '../actions/globalFiltersActions'
import { TOPICS_CLEAR } from '../actions/topicsActions'
import { magicSearchActions } from '../actions/magicSearchActions'
import { Source } from 'apollo/generated/globalTypes'
import { ALL } from 'common/constants/sources'

const moment = extendMoment(Moment)

// Maximum length for the filters url in characters
const MAX_URL_FILTERS_LENGTH = 800

/**
 * Action dispatchers
 */

export const TRANSFORM_FILTERS_DATE_RANGE = 'TRANSFORM_FILTERS_DATE_RANGE'
export const SET_DATE_RANGE = 'filtersv2_SET_DATE_RANGE'
export const SET_FILTER = 'filtersv2_SET_FILTER'
export const ADD_FILTER = 'filtersv2_ADD_FILTER'
export const REMOVE_FILTER = 'filtersv2_REMOVE_FILTER'
export const OVERWRITE_FILTERS = 'filtersv2_OVERWRITE_FILTERS'
export const CLEAR_MENTIONS_FILTERS = 'filtersv2_CLEAR_MENTIONS_FILTERS'
export const REALTIME_START = 'filtersv2_REALTIME_START'
export const REALTIME_STOP = 'filtersv2_REALTIME_STOP'
export const REALTIME_AVAILABLE = 'filtersv2_REALTIME_AVAILABLE'
export const REALTIME_UNAVAILABLE = 'filtersv2_REALTIME_UNAVAILABLE'
export const REALTIME_UPDATE = 'filtersv2_REALTIME_UPDATE'
export const SAVE_VIEW_FROM_FILTERS = 'filtersv2_SAVE_VIEW_FROM_FILTERS'
// -- remember magic filters open/closed
export const SET_FILTER_OPENED = 'filtersv2_SET_FILTER_OPENED'
export const CHANGE_VIEW = 'filtersv2_CHANGE_VIEW'

// update views feature
export const UPDATE_FILTERS_CONTAINER = 'UPDATE_FILTERS_CONTAINER'

/*
Allowed sources keys
*/
const combineFilters = (filters, exclude) =>
  concat(without(exclude, defaultFilters), filters).sort()

const defaultFilters = [
  'aspect',
  'category',
  'feed',
  'followers',
  'author',
  'audience',
  'from',
  'hashtag',
  'isDark',
  'mainMessage',
  'occupation',
  'product',
  'rating',
  'realTimeInterval',
  'sentiment',
  'source',
  'text',
  'theme',
  'to',
  'type',
  'uncategorized',
  'store',
  // 'view', //<- remove view so it wont be included in filters.query
]
const twitterFilters = [
  'age',
  'author',
  'audience',
  'device',
  'gender',
  'interest',
  'location',
  'maritalStatus',
  'mentions',
  'messageId',
  'parentId',
  'url',
]
const facebookFilters = [
  'author',
  'audience',
  'gender',
  'mentions',
  'messageId',
  'parentId',
]

const youtubeFilters = [
  'author',
  'audience',
  'gender',
  'mentions',
  'messageId',
  'parentId',
]
const tiktokFilters = [
  'author',
  'audience',
  'mentions',
  'messageId',
  'parentId',
]
const mediaFilters = [
  'author',
  'audience',
  'mediaType',
  'mentions',
  'messageId',
]
const reviewFilters = [
  'author',
  'geoLocation',
  'store',
  'reviewPlatform',
  'reviewProduct',
]
const linkedinFilters = [
  'author',
  'audience',
  'mentions',
  'messageId',
  'parentId',
]

const sourceFilters = {
  TWITTER: { filters: twitterFilters, exclude: ['isDark', 'rating'] },
  FACEBOOK: { filters: facebookFilters, exclude: ['rating'] },
  INSTAGRAM: { filters: facebookFilters, exclude: ['rating'] },
  YOUTUBE: { filters: youtubeFilters, exclude: ['isDark', 'rating'] },
  TIKTOK: { filters: tiktokFilters, exclude: ['isDark', 'rating'] },
  MEDIA: {
    filters: mediaFilters,
    exclude: ['followers', 'isDark', 'mainMessage', 'rating'],
  },
  REVIEW: { filters: reviewFilters, exclude: ['isDark'] },
  LINKEDIN: { filters: linkedinFilters },
}

export const transformFilterDateRange = dateRange => {
  return {
    type: TRANSFORM_FILTERS_DATE_RANGE,
    payload: { dateRange },
  }
}
export const setDateRange = dateRange => {
  // When the end of the range is rounded to the start of that day, modify it
  // to be the END of that day instead.
  if (dateRange.end.clone().startOf('day').isSame(dateRange.end)) {
    dateRange.end.endOf('day')
  }

  return {
    type: SET_DATE_RANGE,
    payload: { dateRange },
  }
}

export const setFilter = (key, value) => ({
  type: SET_FILTER,
  payload: {
    key: typeof key === 'string' ? key.split('.') : key,
    value,
  },
})

// This differs from set: set overwrites a key when in an operator.
// Add simply pushes to the operator array instead.
export const addFilter = (key, value) => ({
  type: ADD_FILTER,
  payload: {
    key: typeof key === 'string' ? key.split('.') : key,
    value,
  },
})

export const removeFilter = (key, value) => ({
  type: REMOVE_FILTER,
  payload: {
    key: typeof key === 'string' ? key.split('.') : key,
    value,
  },
})

export const overwriteFilters = filters => ({
  type: OVERWRITE_FILTERS,
  payload: {
    filters,
  },
})

export const setFromQueryString =
  ({ query, product }) =>
  dispatch => {
    return dispatch(maybeFetchViews({ product })).then(() => {
      try {
        const filters = decode(query)

        dispatch(
          magicSearchActions.storeMagicSearch(
            omit(['from', 'to', 'view', 'product', 'source'], filters),
          ),
        )
        dispatch(overwriteFilters(filters))

        /**
         * Here at this point we don't have history, since the app
         * has been open by pasting a link in the url or with a
         * open link in new tab action.
         */
        if (window.history.length <= 1) {
          // We need to mark a change, in order to show save/restore buttons
          // in magicSearch
          dispatch(magicSearchActions.changesNotSaved(true))
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Filters value discarded due to error')
      }
    })
  }

export const setInitialFilters =
  ({ product }) =>
  (dispatch, getState) => {
    return dispatch(
      maybeFetchViews({ product, options: { setDefault: true } }),
    ).then(() => {
      const state = getState()
      return get('filters.queryString', state)
    })
  }

export const clearMentionsFilters = ({
  product,
  forceWithFeed = undefined,
}) => {
  // There is an edge-case in explore and maps, where when leaving the mentions
  // view we still want to keep the feed filter.
  // Also, we remove feed filter if user navigates from a trend mentions to product mentions
  const withFeed =
    typeof forceWithFeed === 'boolean'
      ? forceWithFeed
      : !!['explore', 'maps'].includes(product?.type)

  return {
    type: CLEAR_MENTIONS_FILTERS,
    payload: {
      withFeed,
    },
  }
}

export const startRealTime = interval => ({
  type: REALTIME_START,
  payload: { interval },
})

export const stopRealTime = () => ({
  type: REALTIME_STOP,
  payload: {},
})

export const setRealTimeAvailable = () => ({
  type: REALTIME_AVAILABLE,
  payload: {},
})

export const setRealTimeUnavailable = () => ({
  type: REALTIME_UNAVAILABLE,
  payload: {},
})

/**
 * Updates the date range in the state without disabling real-time.
 */
export const updateRealTime = () => ({
  type: REALTIME_UPDATE,
  payload: {},
})

export const setFiltersOpened = status => ({
  type: SET_FILTER_OPENED,
  payload: status,
})

/**
 * Meta actions
 */

export const SET_ALLOWED_SOURCES = 'filtersv2_SET_ALLOWED_SOURCES'
export const RESET_ALLOWED_SOURCES = 'filtersv2_RESET_ALLOWED_SOURCES'

export const setAllowedSources = sources => ({
  type: SET_ALLOWED_SOURCES,
  payload: { sources },
})

export const resetAllowedSources = () => ({
  type: RESET_ALLOWED_SOURCES,
  payload: {},
})

// whenever user change the view from the ViewsFilter selector
export const changeView = view => ({
  type: CHANGE_VIEW,
  payload: {
    currentView: view,
  },
})

export const updateFiltersContainer = filters => ({
  type: UPDATE_FILTERS_CONTAINER,
  payload: {
    filters,
  },
})

/**
 * Reducer function
 */

const updateQueryString = state => {
  // Remove the product from the query as we don't want it to be in the
  // filters
  const q = omit('product', state.query)
  if (q.source === ALL) {
    delete q.source
  }
  if (state.realTimeInterval > 0) {
    q.realTimeInterval = state.realTimeInterval
  }

  let qs = encode(q)
  if (qs.length > MAX_URL_FILTERS_LENGTH) {
    // The query string is too long, just keep the basics in there
    qs = encode(pick(['source', 'from', 'to', 'view'], state.query))
  }

  return set('queryString', qs, state)
}

const resetFilters = (filters, state) => {
  // Make sure we're not un-setting the product
  filters.product = filters.product || state.query.product

  // Ensure we're not setting a source which is not allowed
  if (!state.allowedSources.includes(filters.source || ALL)) {
    const source = state.allowedSources[0]

    if (filters.source !== Source.REVIEW) {
      if (source === ALL) {
        delete filters.source
      } else {
        filters.source = source
      }
    }
  }

  const dateRange = moment.range(new Date(filters.from), new Date(filters.to))
  const realTimeInterval = filters.realTimeInterval || 0
  delete filters.realTimeInterval

  return flow(
    set('query', filters),
    set('dateRange', dateRange),
    set('realTimeInterval', realTimeInterval),
    cleanupFilters,
    updateQueryString,
  )(state)
}

export const fetchAlert = productId => {
  return async dispatch => {
    const alertId = getAlertIdFromParams()

    const safeQueryString = flow(
      defaultTo(undefined, url => isString(url) && url.split('q=')[1]),
      // Remove search param from query string, causes bad decode
      defaultTo(identity, q => isString(q) && q.split('?')[0]),
    )(window.location.href)

    if (safeQueryString && alertId && productId) {
      const alert = await getAlertById(productId, alertId)

      const { category_ids: or, excluded_category_ids: not } = alert

      // Returns (from, to, sentiment, author ) query from url
      const baseFilters = decode(safeQueryString)

      // update alerts filters with alert categories
      const alertFilters = flow(set('category', { or, not }))(baseFilters)

      dispatch(
        setFiltersFromAlert({
          alertFilters,
          alertId,
        }),
      )
      dispatch(setView(null))
    }
  }
}

const mentionFilters = [
  'category',
  'theme',
  'author',
  'audience',
  'mentions',
  'sentiment',
  'gender',
  'location',
  'hashtag',
  'text',
  'aspect',
  'age',
  'maritalStatus',
  'interest',
  'device',
  'messageId',
  'mainMessage',
  'uncategorized',
  'mediaType',
  'url',
  'followers',
  'isDark',
  'type',
  'rating',
  'store',
  'geoLocation',
]
export const excludeMentionsFilters = filters => {
  return omit([...mentionFilters], filters)
}

const getAllowedFilters = source => {
  const allowed = sourceFilters[source]
  return allowed
    ? combineFilters(allowed.filters, allowed.exclude)
    : defaultFilters
}

/**
 * Takes into consideration what filters are not allowed for the source set
 * as active and removes them.
 */
const cleanupFilters = state => {
  const allowed = getAllowedFilters(state.query.source)
  const removeKeys = difference(Object.keys(state.query), allowed)

  const cleanupOperator = val => {
    if (!isObject(val)) return val

    const cleaned = omitBy(v => {
      // Keep whole entities (like numbers and strings)
      if (!isObject(v) && !isArray(v)) return false

      return isEmpty(omitBy(isNil, v))
    }, val)

    return isEmpty(cleaned) ? undefined : cleaned
  }

  const query = flow(
    omit(removeKeys),
    mapValues(cleanupOperator),
    omitBy(isNil),
  )(state.query)

  if (allowed === Source.REVIEW) {
    delete query.source
  }

  return set('query', query, state)
}

const initDateRange = moment.range(
  moment().subtract(6, 'days').startOf('day'),
  moment().endOf('day'),
)

let initialState = {
  // Query is where the filters are maintained in a state compatible with
  // GraphQL, so they can be passed directly.
  query: {
    from: initDateRange.start.valueOf(),
    to: initDateRange.end.valueOf(),
  },

  // dateRange kept as a moment-range. This is what you'll want to use in the
  // components, instead of manually assembling it from the query.
  dateRange: initDateRange,

  // It's initialized down below. Contains the filters representation as a
  // query string
  queryString: '',

  allowedSources: [
    ALL,
    Source.TWITTER,
    Source.FACEBOOK,
    Source.INSTAGRAM,
    Source.YOUTUBE,
    Source.TIKTOK,
    Source.MEDIA,
    Source.REVIEW,
    Source.LINKEDIN,
  ],

  // Realtime availability and state
  realTimeAvailable: false,
  realTimeInterval: 0,
  isFiltersOpened: false,
}

// Set the encoded queryString on the initialState
initialState = updateQueryString(initialState)

export default function filtersReducer(state = initialState, action) {
  const { type, payload } = action

  switch (type) {
    case TRANSFORM_FILTERS_DATE_RANGE: {
      return flow(
        set(
          'dateRange',
          moment.range(payload.dateRange.start, payload.dateRange.end),
        ),
      )(state)
    }
    case GLOBAL_SET_DATE_RANGE: /* @deprecated */
    case SET_DATE_RANGE: {
      const { dateRange } = payload
      if (dateRange.isSame(state.dateRange)) return state

      return flow(
        set('query.from', dateRange.start.valueOf()),
        set('query.to', dateRange.end.valueOf()),
        set('dateRange', dateRange),
        set('realTimeInterval', 0),
        updateQueryString,
      )(state)
    }

    case SET_FILTER: {
      const { key, value } = payload
      return flow(
        set(['query', ...key], value),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case ADD_FILTER: {
      const { key, value } = payload

      let val = value
      if (key.length === 2) {
        // Where there are two keys, it means we're setting an operator
        // (category.or) so we treat it as an array.
        const current = get(['query', ...key], state)

        val = isEmpty(current) ? [value] : [...current, value]
      }

      return flow(
        set(['query', ...key], val),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case REMOVE_FILTER: {
      let { key } = payload
      const { value } = payload

      const numberValue =
        !Number.isNaN(Number(value)) && Number(value) < Number.MAX_SAFE_INTEGER
          ? Number(value)
          : undefined

      let val = []
      if (key.length === 2 && !['match', 'min', 'max'].includes(key[1])) {
        // Remove the complex filters
        const path = !isEmpty(key[1]) ? key[1].split('.') : []
        if (path.length > 1) {
          const valueHasArray = [...value.split(',')].map(item => item.trim()) // remove visual space from value
          const current = get(['query', ...path], state)
          val = isEqual(valueHasArray, current) ? [] : current

          // Remove last level of filter
          if (val.length === 0) {
            path.pop()
            key = path
          }
        } else {
          // Where there are two keys, it means we're setting an operator
          // (category.or) so we treat it as an array.
          // The `match` filter  is ignored because it's not an array.
          const current = get(['query', ...key], state)
          val = remove(x => {
            // we need to check against ex: '1' == 1
            // we just do this if value is a number
            // eslint-disable-next-line eqeqeq
            return numberValue ? x == numberValue : value
          }, current)
        }
      } else if (key.length === 3) {
        // handle geolocation individual removal
        const current = get(['query', ...key], state)
        val = remove(x => x === value, current)
      }

      return flow(
        set(['query', ...key], val),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case FILTERS_REHYDRATE: {
      if (payload && !!payload['filters']) {
        const { filters } = payload

        const safeQueryString = flow(
          defaultTo(undefined, url => isString(url) && url.split('q=')[1]),
          // Remove search param from query string, causes bad decode
          defaultTo(identity, q => isString(q) && q.split('?')[0]),
        )(window.location.href)

        if (!safeQueryString) return state

        const urlFilters = decode(safeQueryString)
        // if persisted state differs with the URL filters, overwrite with urlQueryString

        if (safeQueryString !== filters.queryString && urlFilters.source) {
          return flow(
            set('queryString', safeQueryString),
            set('rehydrated', true),
          )(state)
        }
        return { ...filters, rehydrated: true }
      }

      return { ...state, rehydrated: false }
    }

    case UPDATE_REHYDRATED: {
      return { ...state, rehydrated: payload }
    }

    case SET_FILTERS_FROM_ALERT: {
      const { alertFilters, alertId } = payload

      const updatedFilters = flow(
        set('query.view', null),
        set('alertLoaded', alertId),
        set('isFiltersOpened', true),
      )(resetFilters({ ...alertFilters }, state))

      return updatedFilters
    }

    case CLEAR_ALERT_ID: {
      return set('alertLoaded', undefined, state)
    }

    case OVERWRITE_FILTERS: {
      const { filters } = payload
      return resetFilters(filters, state)
    }

    case SET_ALLOWED_SOURCES: {
      const { sources } = payload
      const source = sources.includes(state.source) ? state.source : sources[0]

      return flow(
        set('query.source', source === ALL ? undefined : source),
        set('allowedSources', [...sources]),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case GLOBAL_RESET_ALLOWED_SOURCES:
    case RESET_ALLOWED_SOURCES: {
      return flow(
        set('allowedSources', [...initialState.allowedSources]),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case CLEAR_MENTIONS_FILTERS: {
      const query = omit(
        [...mentionFilters, ...(payload.withFeed ? [] : ['feed'])],
        state.query,
      )

      return flow(set('query', query), updateQueryString)(state)
    }

    case REALTIME_START: {
      const { interval } = payload
      const dateRange = moment.range(
        moment().subtract(interval, 'minutes'),
        moment(),
      )

      return flow(
        set('realTimeInterval', interval),
        set('dateRange', dateRange),
        set('query.from', dateRange.start.valueOf()),
        set('query.to', dateRange.end.valueOf()),
        updateQueryString,
      )(state)
    }

    case REALTIME_STOP: {
      return flow(set('realTimeInterval', 0), updateQueryString)(state)
    }

    case REALTIME_AVAILABLE: {
      return set('realTimeAvailable', true, state)
    }

    case REALTIME_UNAVAILABLE: {
      return flow(
        set('realTimeAvailable', false),
        set('realTimeInterval', 0),
      )(state)
    }

    case REALTIME_UPDATE: {
      const dateRange = moment.range(
        moment().subtract(state.realTimeInterval, 'minutes'),
        moment(),
      )

      return flow(
        set('dateRange', dateRange),
        set('query.from', dateRange.start.valueOf()),
        set('query.to', dateRange.end.valueOf()),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    // Backwards-compatible events.
    // Those events are fired by globalFiltersActions and should be removed
    // one the globalFilters reducer is gone.

    case GLOBAL_SET_DEFAULT_DATE: {
      return flow(
        set('dateRange', initDateRange),
        set('query.from', initDateRange.start.valueOf()),
        set('query.to', initDateRange.end.valueOf()),
        updateQueryString,
      )(state)
    }

    case GLOBAL_SET_VIEW: {
      return flow(
        set('query.view', payload.view),
        set('realTimeInterval', 0),
        updateQueryString,
      )(state)
    }

    case GLOBAL_SET_SOURCE: {
      const source = payload.source.toUpperCase()
      return flow(
        set('query.source', source === ALL ? undefined : source),
        set('realTimeInterval', 0),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    /**
     * TODO:
     * The next two cases are used by the content page in MAPS.
     * They should eventually be refactored.
     */
    case GLOBAL_SET_USER_FILTER: {
      return flow(
        set(
          'query.author.or',
          payload.user === 'all' || !payload.user ? [] : [payload.user],
        ),
        cleanupFilters,
        updateQueryString,
      )(state)
    }
    case TOPICS_CLEAR: {
      return flow(
        set('query.author.or', []),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case GLOBAL_SET_PRODUCT: {
      return flow(
        set('query.product', payload.productId),
        updateQueryString,
      )(state)
    }

    case GLOBAL_SET_FEED: {
      if (urlIsExploreTrends(window.location.pathname)) {
        return flow(
          set('query.feed.or', [payload.feedId]),
          cleanupFilters,
          updateQueryString,
        )(state)
      }

      return state
    }

    case GLOBAL_RESET_FEED: {
      return flow(
        set('query.feed.or', []),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case GLOBAL_SET_ALLOWED_SOURCES: {
      const sources = payload.sources.map(s => s.toUpperCase())
      const source = sources.includes(state.query.source)
        ? state.query.source
        : sources[0]

      return flow(
        set('query.source', source === ALL ? undefined : source),
        set('allowedSources', [...sources]),
        cleanupFilters,
        updateQueryString,
      )(state)
    }

    case SET_FILTER_OPENED: {
      return set('isFiltersOpened', payload, state)
    }

    case PURGE: {
      return initialState
    }

    // when change the view from the selector then remove categories
    // filters from the query and keep the remaining filters
    case CHANGE_VIEW: {
      const { from, to, source, product, view, feed } = state.query
      const basicQuery = { from, to, source, product, view }

      // Keep feed filters for explore trends.
      if (urlIsExploreTrends(window.location.pathname)) {
        return { ...state, query: { ...basicQuery, feed } }
      }

      return !isEqual(state.query.view, payload.currentView)
        ? { ...state, query: basicQuery }
        : state
    }

    case UPDATE_FILTERS_CONTAINER: {
      const filtersQuery = state.query
      const update = merge(filtersQuery, payload.filters)
      return set('query', update, state)
    }

    default:
      return state
  }
}
