/* eslint-disable no-underscore-dangle */
import ReactDOM from 'react-dom'
import React, { Suspense } from 'react'
import mixpanel from 'mixpanel-browser'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { Provider } from 'react-redux'
import reportWebVitals from './reportWebVitals'
import { PersistGate } from 'redux-persist/integration/react'
import { ProSidebarProvider } from 'react-pro-sidebar'

/* Apollo */
import { ApolloProvider } from '@apollo/client'

import './sentry'
import App from './containers/App/App'
import ScrollToTop from 'components/ScrollToTop/ScrollToTop'
import { persistor, store } from 'store/store'
import { unregister } from './registerServiceWorker'

// Global css rules
import 'react-datepicker/dist/react-datepicker.min.css'
import './css/global.css'
import 'sentisis-components/dist/styles.css'

// localize plugin i18next
import './i18n'
import Loading from 'components/Loading/Loading'
import { TranslationServiceProvider } from './contexts/translation-context'
import apolloClient from 'apollo/client'

// Replace builtin promises with blebird
global.Promise = require('bluebird')

const isProduction = process.env.REACT_APP_ENV === 'production'

;(Promise as any).config({
  cancellation: true,
  longStackTraces: !isProduction,
  warnings: !isProduction,
})

// Add Mixpanel metrics
mixpanel.init('adc3ba22b5db5c4cf5f013a0a82d0626')

const target = document.getElementById('sentisis-app')

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <ProSidebarProvider>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={Loading}>
              <TranslationServiceProvider>
                <Router>
                  <ScrollToTop>
                    <Route component={App} />
                  </ScrollToTop>
                </Router>
              </TranslationServiceProvider>
            </Suspense>
          </PersistGate>
        </ProSidebarProvider>
      </Provider>
    </ApolloProvider>
  </React.StrictMode>,
  target,
)

// Unregister the serviceWorker, we don't want to use it anymore
unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
