import * as Sentry from '@sentry/react'

const isProduction = process.env.REACT_APP_ENV === 'production'
const allowUrls = [/sentisis\.io/]

Sentry.init({
  dsn: 'https://5cb8ab4ff44242c3a56ec9a0e888b0df@app.getsentry.com/64804',
  allowUrls,
  release: process.env.VERSION,
  environment: process.env.REACT_APP_ENV,
  ignoreErrors: [
    "Cannot read property 'componentWillUnmount' of null",
    "Cannot read property 'remove' of undefined",
    "Cannot read property '_currentElement' of null",
    'Minified exception occurred; use the non-minified dev environment for the full error message and additional helpful warnings.',
    'Unexpected identifier',
    'Unexpected token <',
    'captureException(raven-js/src/raven)',
    'Network error: Failed to fetch',
    'Maximum update depth exceeded',
  ],
  enabled: isProduction,
})
