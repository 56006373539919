import styled from 'styled-components'
import { InputLabel as label, TextField as input } from '@material-ui/core'
import DefaultIcon from 'components/Icon/Icon'
import DefaultIconV2 from 'components/IconV2'

export const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
`

export const Label = styled(label)<{ $error: boolean }>`
  && {
    margin-bottom: 4px;
    color: ${({ $error, theme }) =>
      $error ? theme.colors.error : theme.colors.darkGray};
    line-height: 18px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
`

export const Input = styled(input)`
  && {
    background-color: ${({ theme }) => theme.colors.white};
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.colours.gray[50]};
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.lightGray};
  }

  :hover ::placeholder {
    color: ${({ theme }) => theme.colours.gray[50]};
  }

  .MuiInputBase-root {
    color: ${({ theme }) => theme.colours.gray[50]};
    height: 48px;
  }

  .Mui-focused {
    color: ${({ theme }) => theme.colours.gray[50]};
  }

  .MuiOutlinedInput-inputAdornedEnd {
    padding-right: 12px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${({ theme }) => theme.colours.white[5]};
    border-radius: 2px;
  }

  .MuiOutlinedInput-root {
    :hover .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colours.gray[5]};
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colours.gray[5]};
      border-width: 1px;
    }

    &.Mui-error {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.error};
      }
      :hover .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.error};
      }
    }

    &.Mui-disabled {
      border-color: ${({ theme }) => theme.colours.gray[5]};
      background-color: ${({ theme }) => theme.colours.white[10]};
      color: ${({ theme }) => theme.colours.gray[50]};

      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colours.white[10]};
      }

      &.MuiInputBase-adornedStart {
        svg {
          color: ${({ theme }) => theme.colours.gray[55]};
        }
        .MuiInputBase-inputAdornedStart {
          margin-left: 12px;
        }
      }
    }
  }
`

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 5px;
`

export const CustomInputcontainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white} !important;
`

export const IconContainer = styled.span``

export const Icon = styled(DefaultIcon)<{ $hasAction: boolean }>`
  color: ${({ theme }) => theme.colours.gray[50]};
  cursor: ${({ $hasAction }) => ($hasAction ? 'pointer' : 'default')};
`

export const ErrorIcon = styled(DefaultIconV2).attrs({
  name: 'error',
  size: 14,
})`
  color: ${({ theme }) => theme.colours.red[70]};
`

export const EyeClosed = styled(DefaultIconV2).attrs({
  name: 'eyeClosed',
  size: 16,
})`
  color: ${({ theme }) => theme.colours.gray[160]};
`

export const EyeOpen = styled(DefaultIconV2).attrs({
  name: 'eyeOpen',
  size: 16,
})`
  color: ${({ theme }) => theme.colours.gray[170]};
`
