import styled from 'styled-components/macro'

export const Placeholder = styled.div`
  align-items: center;
  border: 2px solid ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.red};
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
`

export const Container = styled.span<{ $width: number; $height: number }>`
  min-width: ${({ $width }) => `${$width}px`};
  min-height: ${({ $height }) => `${$height}px`};
`
