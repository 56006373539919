import { FC, memo } from 'react'
import * as S from './styles'
import { Props } from './types'

export const Snackbar: FC<Props> = ({
  children,
  isOpen,
  title,
  type = 'info',
  iconName = 'error',
  onClose,
  horizontal = 'center',
  vertical = 'bottom',
  autoHideDuration = 6000,
  minWidth = '400px',
}) => {
  return (
    <S.Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal,
      }}
      open={isOpen}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      $minWidth={minWidth}
    >
      <S.Content $type={type}>
        <S.Left>
          {iconName && (
            <S.IconContainer>
              <S.Icon name={iconName} />
            </S.IconContainer>
          )}
          <S.TextContainer>
            {title && <S.Title>{title}</S.Title>}
            <S.Desc>{children}</S.Desc>
          </S.TextContainer>
        </S.Left>
        <S.CloseContainer>
          <S.CloseButton onClick={onClose}>
            <S.CloseIcon name="close" />
          </S.CloseButton>
        </S.CloseContainer>
      </S.Content>
    </S.Snackbar>
  )
}

export default memo(Snackbar)
